import React, {Component} from 'react'
import {Table, Form} from 'react-bootstrap'

import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink  } from '@react-pdf/renderer';
import MyDocument from '../document/mydoc'
import ReceiptService from '../../services/receipt.service'
import paymentService from '../../services/payment.service'
import {isNotPanel} from '../../helpers/utility'
import consultationService from '../../services/consultation.service';


export default class ReceiptPreview extends Component {
    constructor(props){
        super(props)
        var visitId = this.props.match.params.id
        var details = this.props.match.params.details
        var receiptNo = this.props.match.params.receiptno
       
        this.state = {
            receipt: [],
            redirect: null,
            visitId,
            details,
            invoiceNo: receiptNo,
            paymentId: null,
            payments: []
        }

        
    }

    componentDidMount = () => {
        var visitId = this.state.visitId
        let data = {
            visitId
        }
        
             

        ReceiptService.load(data).then(result => {
            const { discount, drFee, clinicName, clinicAddres, patientId, name, dateVisit, medicineInfo, paymentId, cardType} =  result.data[0]
            if(result){
                this.setState({
                  clinicName,
                  clinicAddres,
                  patientId,
                  name,
                  dateVisit,
                  medicineInfo,
                  discount,
                  drFee,
                  paymentId,
                  cardType
                })
              }
        })

        paymentService.getPayments().then(result => {
            if(result){
                this.setState({
                    payments: result.data
                })
            }
        })

        let info = {
            visitId: parseInt(this.state.visitId)
        }

        consultationService.getInvoiceNoByVisitId(info).then(result => {
            if(result){
                var receipt = result.data.receiptNo
                if(receipt == null){
                    consultationService.getInvoiceNo().then(result => {
                        if(result){
                            this.addReceipt(result.data)
                        }
                    })
                }
            }
        })


    }
    
    addReceipt = (receiptNo)=>{
        this.setState({
            invoiceNo: receiptNo
        })
        let info = {
            visitId: parseInt(this.state.visitId),
            receiptNo: receiptNo
        }
        consultationService.addReceipt(info).then(result => {
            if(result){
                consultationService.updInvoiceCounter().then(result => {

                })
            }
        })
    }

    renderTableHeader() {
        
        return (
            <tr>
                <th key="0">No</th>
                <th key="1">Particulars</th>
                <th key="2">Quantity</th>
                <th key="3">Price</th>
                <th key="4">Amount</th>
            </tr>
        );
    }

    renderTableDataDetails() {
    if(this.state.medicineInfo == null) return
    return this.state.medicineInfo.map((medicine, index) => {
        const { name, drugName, quantity, price, panelPrice } = medicine //destructuring
        return (
            <tr key={index}>
                <td>{index}</td>
                <td>{drugName}</td>
                <td>{quantity}</td>
                <td>{isNotPanel(this.state.payments, this.state.paymentId) ? price : panelPrice}</td>
                <td>{quantity*(isNotPanel(this.state.payments, this.state.paymentId) ? price : panelPrice)}</td>
            </tr>
        )
    })
    }

    renderTableDataLumSum() {
        if(this.state.medicineInfo == null) return
        let sum = this.state.medicineInfo.map((x, index) => {
            return x.quantity*(isNotPanel(this.state.payments, this.state.paymentId) ? x.price : x.panelPrice)
        }).reduce((total, num)=> {
            return total + num
          });

           return (
                <tr key="0">
                    <td>0</td>
                    <td>Medications</td>
                    <td>1</td>
                    <td>{sum}</td>
                    <td>{sum}</td>
                </tr>
            )
        }
    
    renderDiscount = () => {
        if(this.state.discount!=null){
            return (
                <tr key={this.state.details == "true" ? this.state.medicineInfo.length + 1 : 3}>
                <td>{this.state.details == "true" ? this.state.medicineInfo.length + 1 : 3}</td>
                <td>Discount</td>
                <td></td>
                <td></td>
                <td>{this.state.discount}%</td>
                </tr>
            ) 
        }
    }
    renderTotal = () => {
        if(this.state.medicineInfo!=null){
          var total=this.state.medicineInfo.map(x => {
            return (isNotPanel(this.state.payments, this.state.paymentId) ? x.price : x.panelPrice)*x.quantity
          })
    
          if(total.length > 0) {
            var sum = total.reduce((total, num)=> {
              return total + num
            })
          }
    
          sum = sum + this.state.drFee 
          if(this.state.discount != null){
            let discount = (100 - this.state.discount) / 100
            sum = sum * discount
          }

         return sum

       }
    }
    

    render(){
        return (
            <>
            {/* <PDFViewer>
               <MyDocument ></MyDocument>
            </PDFViewer> */}
                  
            <div className="content" style={{marginTop:"100px"}}>
            <h2>Receipt</h2>
                <div className="formClinic">
                    <div className="row">
                        <div className='col-lg-6 logo'>
                        
                        </div> 
                        <div className="col-lg-6">
                            <Form.Group controlId="clinicName">
                                <Form.Label>{this.state.clinicName}</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="clinicAddres">
                                <Form.Label>{this.state.clinicAddres}</Form.Label>
                            </Form.Group>
                        </div>
                        {/* <div className="col-lg-12">
                            <Form.Group controlId="clinicAddres">
                                <Form.Label>{this.state.clinicAddres}</Form.Label>
                            </Form.Group>
                        </div> */}
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Group controlId="PatientName">
                                <Form.Label>Name: {this.state.name}</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="PatientId">
                                <Form.Label>IcNo: {this.state.patientId}</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Group controlId="ReceiptNo">
                                <Form.Label>Receipt No: {this.state.invoiceNo}</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="DateVisit">
                                <Form.Label>Date: {this.state.dateVisit}</Form.Label>
                            </Form.Group>
                        </div>
                    </div>


                    <div className="row" style={{marginTop:"20px"}}>
                  
                    <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.state.details === "true" ? this.renderTableDataDetails() : this.renderTableDataLumSum()}
                            {this.state.medicineInfo != null ? (
                            <tr key={this.state.details == "true" ? this.state.medicineInfo.length : 1}>
                                <td>{this.state.details == "true" ? this.state.medicineInfo.length : 1}</td>
                                <td>Consultation Fees</td>
                                <td>{1}</td>
                                <td>{this.state.drFee}</td>
                                <td>{this.state.drFee}</td>
                                </tr>
                            ) : <></>}
                            {this.state.discount ? this.renderDiscount(): <></>}
                        </tbody>
                    </Table>
                  
                    </div>

                    <div className="row" style={{marginTop:"40px"}}>
                        <div className="col-lg-6">
                        {/* {setTimeout(() => {
                           return (
                            <PDFDownloadLink document={<MyDocument invoice={this.state.invoiceNo} data={this.state.visitId} details={this.state.details} />} fileName="somename.pdf">
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Print now!')}
                       </PDFDownloadLink>  
                           )
                        }, 10000)

                        } */}
                        <PDFDownloadLink document={<MyDocument invoice={this.state.invoiceNo} data={this.state.visitId} details={this.state.details} />} fileName={this.state.invoiceNo}>
                             {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Print now!')}
                        </PDFDownloadLink>    
                        </div>
                        
                      <div className="col-lg-6">
                          <span className="pull-right" style={{backgroundColor:'red', fontWeight: 'bold', fontSize:20, padding:10}}>Total pay: RM {this.renderTotal()}</span>
                       </div>    
                   
                    </div>

                </div>
              
            </div>
            <div className="docMobile" style={{marginTop:"100px"}}>
            <h2>Receipt</h2>
                <div className="formClinic">
                    <div className="row">
                        <div className='col-lg-6 logo'>
                        
                        </div> 
                        <div className="col-lg-6">
                            <Form.Group controlId="clinicName">
                                <Form.Label>{this.state.clinicName}</Form.Label>
                            </Form.Group>
                            <Form.Group controlId="clinicAddres">
                                <Form.Label>{this.state.clinicAddres}</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Group controlId="PatientName">
                                <Form.Label>Name: {this.state.name}</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="PatientId">
                                <Form.Label>IcNo: {this.state.patientId}</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Group controlId="ReceiptNo">
                                <Form.Label>Receipt No: {this.state.invoiceNo}</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <Form.Group controlId="DateVisit">
                                <Form.Label>Date: {this.state.dateVisit}</Form.Label>
                            </Form.Group>
                        </div>
                    </div>


                    <div className="row" style={{marginTop:"20px"}}>
                  
                    <Table responsive striped bordered hover size="sm" variant="dark">
                        <thead>
                            {this.renderTableHeader()}
                        </thead>
                        <tbody>
                            {this.state.details === "true" ? this.renderTableDataDetails() : this.renderTableDataLumSum()}
                            {this.state.medicineInfo != null ? (
                            <tr key={this.state.details == "true" ? this.state.medicineInfo.length : 1}>
                                <td>{this.state.details == "true" ? this.state.medicineInfo.length : 1}</td>
                                <td>Consultation Fees</td>
                                <td>{1}</td>
                                <td>{this.state.drFee}</td>
                                <td>{this.state.drFee}</td>
                                </tr>
                            ) : <></>}
                            {this.state.discount ? this.renderDiscount(): <></>}
                        </tbody>
                    </Table>
                  
                    </div>

                    <div className="row" style={{marginTop:"40px"}}>
                        <div className="col-lg-6">

                        <PDFDownloadLink document={<MyDocument invoice={this.state.invoiceNo} data={this.state.visitId} details={this.state.details} />} fileName={this.state.invoiceNo}>
                             {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Print now!')}
                        </PDFDownloadLink>    
                        </div>
                        
                      <div className="col-lg-6">
                          <span className="pull-right" style={{backgroundColor:'red', fontWeight: 'bold', fontSize:20, padding:10}}>Total pay: RM {this.renderTotal()}</span>
                       </div>    
                   
                    </div>

                </div>
              
            </div>
            </>
        )
    }

}